
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
































































::v-deep {
  .carousel__item {
    flex-basis: 60%;

    @include mq(l) {
      flex-basis: 32%;
    }
  }

  .discover-offers {
    /* stylelint-disable-next-line declaration-no-important */
    padding-top: 11rem !important;
    /* stylelint-disable-next-line declaration-no-important */
    padding-bottom: 11rem !important;
    background-color: #ebebeb;
  }

  .discover-offers .carousel__item {
    /* stylelint-disable-next-line declaration-no-important */
    flex-basis: 20% !important;
  }

  .tv-choice {
    background: #f7f7f7;

    .carousel__item {
      flex-basis: 50%;
    }
  }

  .tv-channels-list {
    padding-top: 2rem;

    .tv-channels-list__list {
      background: none !important;
      box-shadow: none;

      &::before,
      &::after {
        display: none;
      }
    }
  }

  .tv-channels-list__title {
    max-width: inherit;
  }
}

.channels {
  position: relative;
  z-index: 1;
  min-height: 100vh;
}

.channels__discover {
  z-index: 1;
  background: $c-light-gray;
}

.liste__el ::v-deep p {
  font-size: 30px;
  line-height: 36px;
}
