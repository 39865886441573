
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



















































































































.experiences {
  position: relative;
  z-index: 10;
  color: #fff;
  text-align: center;

  .experiences__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .picture {
    display: block;
    height: 5rem;
    aspect-ratio: 2 / 1;
    object-fit: contain;

    @include mq(l) {
      height: 8rem;
    }
  }
}

.experiences__title {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    transform: translateY(-5px);
    width: auto;
    height: 35px;

    @include mq(l) {
      height: 45px;
    }
  }

  @include mq(l) {
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
  }
}

.liste__el {
  font-size: 16px;
  line-height: 22px;

  @include mq(l) {
    font-size: 30px;
    line-height: 36px;
  }
}

.experiences__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 4rem 0;
  padding: 0;

  @include mq(l) {
    grid-template-columns: repeat(4, 1fr);
  }
}
