
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






















































































































.channels-slider ::v-deep .carousel__item {
  @include mq($until: s) {
    flex-basis: 87%;
  }
}

.channels-slider {
  position: relative;
  z-index: 10;
  color: #fff;
  text-align: center;

  h2 {
    margin-bottom: 0;
  }

  .subtitle {
    margin-top: 1.4rem;
    margin-bottom: 6rem;
  }
}

.card {
  position: relative;
  overflow: hidden;
  height: 100%;
  color: #fff;
  background-color: rgba(37, 51, 74, 100%);
  border-radius: 20px;
}

.card__top {
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 1 / 1.15;

  &::before {
    @include get-all-space;

    z-index: 3;
    content: '';
    display: block;
    background: linear-gradient(
      180deg,
      rgba(8, 18, 31, 0) 66.55%,
      #08121f 100%
    );

    /* visuel */
  }
}

.card__top__infos {
  position: absolute;
  z-index: 4;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  gap: 2rem;

  span {
    min-height: 6rem;
  }

  @include mq(xl) {
    width: 70%;
  }
}

.infos__logo {
  display: block;
  height: 5rem;
  aspect-ratio: 2 / 1;
  object-fit: contain;

  @include mq(xl) {
    height: 7rem;
  }
}

.top__picture {
  @include get-all-space;

  z-index: 1;
  object-fit: cover;
  opacity: 0.4;
}

.card__bottom__infos {
  position: relative;
  z-index: 10;
  padding: 5rem 2rem;
  text-align: center;

  h3 {
    margin-top: 0;
    margin-bottom: 5rem;
  }

  .card__label {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.bottom__link {
  position: absolute;
  z-index: 20;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
}
